<template>
  <div>
    <!-- Header Alert -->
    <b-alert show variant="light" class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Surat Permohonan Praktek Keterampilan Mengajar</b></div>
      <b-button squared @click="gotoPage('/letters/pkm-application-form/add')" v-if="btnAccess" variant="primary">Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-end mt-n3">
                <div class="col-md-8">
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('days')"
                    v-bind:class="{
                      'btn-info': btnActive.days,
                      'btn-outline-info': btnActive.days == false,
                    }"
                  >
                    Hari Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('month')"
                    v-bind:class="{
                      'btn-info': btnActive.month,
                      'btn-outline-info': btnActive.month == false,
                    }"
                  >
                    Bulan Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('lastMonth')"
                    v-bind:class="{
                      'btn-info': btnActive.lastMonth,
                      'btn-outline-info': btnActive.lastMonth == false,
                    }"
                  >
                    Bulan Kemarin
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('year')"
                    v-bind:class="{
                      'btn-info': btnActive.year,
                      'btn-outline-info': btnActive.year == false,
                    }"
                  >
                    Tahun Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    v-bind:class="{
                      'btn-info': btnActive.period,
                      'btn-outline-info': btnActive.period == false,
                    }"
                    @click="btnPeriodeOnClick"
                  >
                    Periode
                  </button>
                </div>
                <div class="col-md-4 px-0">
                  <b-input-group>
                    <template #append>
                      <b-button variant="success" squared size="sm" @click="search"> Cari </b-button>
                      <b-button variant="danger" squared size="sm" @click="reset"> Reset </b-button>
                    </template>
                    <b-form-input type="text" v-model="filter.name" placeholder="Cari Nama"></b-form-input>
                  </b-input-group>
                </div>
              </div>

              <!-- timeseries filter -->
              <div class="row mt-3">
                <div class="col-md-9" v-if="onShowPeriod">
                  <span class="d-none d-sm-block">
                    <b-input-group>
                      <b-form-input v-model="filter.start_date" type="text" placeholder="Awal" autocomplete="off" readonly></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker v-model="filter.start_date" button-only right reset-button locale="id" aria-controls="example-input"></b-form-datepicker>
                      </b-input-group-append>
                      <b-button squared variant="info">s/d</b-button>
                      <b-form-input v-model="filter.end_date" type="text" placeholder="Akhir" autocomplete="off" readonly></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker v-model="filter.end_date" button-only right reset-button locale="id" aria-controls="example-input"></b-form-datepicker>
                      </b-input-group-append>
                      <!-- <template #append>
                        <b-button squared @click="pagination" variant="success"
                          >Cari</b-button
                        >
                        <b-button squared @click="reset" variant="danger"
                          >Reset</b-button
                        >
                      </template> -->
                    </b-input-group>
                  </span>
                  <span class="d-block d-sm-none">
                    <div class="row">
                      <div class="col-12 pb-1">
                        <b-input-group>
                          <b-form-input v-model="filter.start_date" type="text" placeholder="Awal" autocomplete="off" readonly></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker v-model="filter.start_date" button-only right reset-button locale="id" aria-controls="example-input"></b-form-datepicker>
                          </b-input-group-append>
                          <!-- <template #append>
                            <b-button
                              squared
                              @click="pagination"
                              variant="success"
                              >Cari</b-button
                            >
                            <b-button
                              squared
                              @click="reset"
                              variant="danger"
                              >Reset</b-button
                            >
                          </template> -->
                        </b-input-group>
                      </div>
                      <div class="col-12 pt-0">
                        <b-input-group>
                          <b-button squared variant="info">s/d</b-button>
                          <b-form-input v-model="filter.end_date" type="text" placeholder="Akhir" autocomplete="off" readonly></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker v-model="filter.end_date" button-only right reset-button locale="id" aria-controls="example-input"></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <b-table striped hover :items="items" :fields="fields" class="mt-3" responsive>
                <template #table-colgroup="scope">
                  <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'action' ? '15%' : '' }" />
                </template>

                <template #cell(photo)="data">
                  <div class="d-flex justify-content-start align-items-center image-container">
                    <div class="image-input-wrapper" style="position: relative">
                      <img class="image" :src="data.item.photo_url" />
                    </div>
                  </div>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="gotoPage(`/letters/pkm-application-form/detail/${data.item.id}`)"
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="gotoPage(`/letters/pkm-application-form/edit/${data.item.id}`)"
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button size="sm" class="btn-circle btn-outline-danger mr-1" v-b-tooltip.hover title="Hapus" @click="deleteData(data.item.id)" v-if="btnAccess"><i class="fas fa-trash px-0"></i></b-button>
                  <b-button size="sm" class="btn-circle mr-1" variant="outline-green" v-b-tooltip.hover title="Unggah Surat Balasan" @click="reply(data.item)" v-if="currentUser.role_id != 2">
                    <i class="fas fa-solid fa-upload px-0"></i>
                  </b-button>
                  <b-button
                    v-if="data.item.reply != ''"
                    size="sm"
                    class="btn-circle"
                    variant="outline-primary"
                    v-b-tooltip.hover
                    title="Unduh Surat Balasan"
                  >
                    <a :href="data.item.reply" target="_blank">
                      <i class="fas fa-solid fa-download px-0"></i>
                    </a>
                  </b-button>
                </template>
              </b-table>

              <b-pagination v-if="items.length != 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @page-click="pageOnClick" class="mt-4"></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-reply" @ok="submitReply">
      <template #modal-title>Unggah Surat Balasan</template>

      <b-form-file v-model="formReply.reply" :state="Boolean(formReply.reply)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept=".pdf" @change="chooseFile"></b-form-file>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  data() {
    return {
      currentUser: getUser(),
      // filter
      filter: {
        name: "",
        student_id: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "created_at_display",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIM",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Mahasiswa",
          sortable: true,
        },
        // {
        //   key: "letter_type_name",
        //   label: "Jenis Surat",
        //   sortable: true,
        // },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // Form Reply
      formReply: {
        form_id: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      // access
      btnAccess: true,
    };
  },
  methods: {
    gotoPage(route) {
      this.$router.push(`${route}`);
      window.btnActive = this.btnActive;
      window.filter = this.filter;
      window.pagination = {
        currentPage: this.currentPage,
        totalRows: this.totalRows,
        perPage: this.perPage
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&name=${this.filter.name}`;

      if (getUser().role_id == 2) {
        filterParams += `&student_id=${getUser().id}`;
      }

      let response = await module.paginate("api/pkm-application-forms", `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`);
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    reply(data) {
      console.log("data", data);
      this.formReply.form_id = data.form_id;
      console.log("formreply", this.formReply);
      this.$bvModal.show("modal-reply");
    },
    chooseFile(evt) {
      this.formReply.reply = evt.target.files[0];
    },
    async submitReply(bvModalEvt) {
      bvModalEvt.preventDefault();

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.formReply)) {
        formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(formData, `api/forms/${this.formReply.form_id}`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors);
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.pagination();
        this.$bvModal.hide("modal-reply");
      }
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      // set btn Active
      this.btnActive.days = true;
      this.btnActive.month = false;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = false;

      this.filter.start_date = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

      this.pagination();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      console.log(type);
      if (type == "days") {
        // set time
        this.filter.start_date = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment().startOf("month").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment().endOf("month").endOf("day").format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment().subtract(1, "months").startOf("month").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment().subtract(1, "months").endOf("month").endOf("day").format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment().startOf("year").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment().endOf("year").endOf("day").format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/pkm-application-forms/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display");
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1109") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Surat Permohonan Praktek Keterampilan Mengajar", route: "/pkm-application-form" }]);
    if (window.hasOwnProperty("btnActive")) {
      this.btnActive.days = false;
      this.btnActive.month = false;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = false;

      for (const [key, value] of Object.entries(window.btnActive)) {
        if (value) {
          if (key == "days") {
            this.btnActive.days = true;
          } else if (key == "month") {
            this.btnActive.month = true;
          } else if (key == "lastMonth") {
            this.btnActive.lastMonth = true;
          } else if (key == "period") {
            this.btnActive.period = true;
            setTimeout(() => {
              this.filter.start_date = window.filter.start_date;
              this.filter.end_date = window.filter.end_date;
              this.pagination();
            }, 1000);
            this.onShowPeriod = true;
          } else if (key == "year") {
            this.btnActive.year = true;
          }
          this.btnActiveOnClick(key);
        }
      }
    }
    if (window.hasOwnProperty("pagination")) {
      this.totalRows = window.pagination.totalRows;
      this.currentPage = window.pagination.currentPage;
      this.perPage = window.pagination.perPage;
    }
    this.pagination();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
